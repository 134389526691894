<!-- eslint-disable -->
<template>
    <main>
      <section class="legal-section">
          <button @click="$router.go(-1)" class="modal-close"><span></span><span></span><span></span></button>
        <div class="grid-container">
          <div class="grid-row">
            <div class="col-12">
                <h3>Wiederrufsbelehrung</h3>
                <p>Widerrufsrecht <br></p><br>
                <p>Sie können Ihre Vertragserklärung innerhalb von einem Monat ohne Angabe von Gründen in Textform (z. B. Brief, Fax, E-Mail) widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht vor Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 § 2 in Verbindung mit § 1 Absatz 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312g Absatz 1 Satz 1 BGB in Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs. Der Widerruf ist zu richten an: <br></p><br>
                <p>Rechtsanwalt Alexander Biernacki <br> Domplatz 12a <br> 06618 Naumburg <br></p><br>
                <p>E-Mail-Adresse: kontakt@scheidungsexpress.de <br></p><br>
                <p> Widerrufsfolgen Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf. gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung sowie Nutzungen (z. B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren beziehungsweise herausgeben, müssen Sie uns insoweit Wertersatz leisten. Dies kann dazu führen, dass Sie die vertraglichen Zahlungsverpflichtungen für den Zeitraum bis zum Widerruf gleichwohl erfüllen müssen. Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer Widerrufserklärung, für uns mit deren Empfang. <br></p><br>
                <p>Besondere Hinweise <br> Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben. <br></p><br>
                <p>Ende der Widerrufsbelehrung</p>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
export default {
  name: 'Revocation'
};
</script>
