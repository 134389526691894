<template>
    <main id="app">
        <Aside />
        <HeaderMobile />
        <Form />
        <Footer />
    </main>
</template>
<script>
export default {
    components: {
        Aside: () => import('./Aside'),
        HeaderMobile: () => import('./HeaderMobile'),
        Form: () => import('./Form'),
        Footer: () => import('./Footer'),
    },
    created() {
        if (this.$route.query.ref !== undefined || this.$route.query.ref !== '') {
            this.$store.dispatch('setRefCode', this.$route.query.ref);
        }
    },
}
</script>