<!-- eslint-disable -->
<template>
    <main>
      <section class="legal-section">
          <button @click="$router.go(-1)" class="modal-close"><span></span><span></span><span></span></button>
        <div class="grid-container">
          <div class="grid-row">
            <div class="col-12">
                <h1>Impressum</h1>
                <h5>Diensteanbieter und Verantwortlicher im Sinne des § 5 Telemediengesetz</h5><br>
                <p>Rechtsanwalt Alexander Biernacki <br> Domplatz 12a 06618 Naumburg <br> Tel. 03445/797900 <br> Fax. 03445/7979001 <br></p><br>

                <p>E-Mail: kontakt@scheidungsexpress.de <br> Gesetzliche Berufsbezeichnung / Verleihender Staat: <br> Rechtsanwalt / Bundesrepublik Deutschland <br></p><br>
                <p>Zuständige Aufsichtsbehörde:</p><br>
                <p>Rechtsanwaltskammer des Landes Sachsen-Anhalt, Gerhart-Hauptmann-Strasse 5, 39108 <br> Magdeburg, Telefon: 0391-252 72 10, Telefax: 0391-25 27 203 <br></p>
                <p>Berufsrechtliche Regelungen: <br></p>
                <p>Für die Tätigkeit von Rechtsanwälten in der Bundesrepublik Deutschland gelten folgende berufsrechtliche Regelungen: <br></p><br>
                <p>* Bundesrechtsanwaltsordnung (BRAO) <br> * Berufsordnung der Rechtsanwälte (BORA) <br> * Fachanwaltsordnung (FAO) <br> * Gesetz über die Vergütung der Rechtsanwältinnen und Rechtsanwälte (Rechtsanwaltsvergütungsgesetz – RVG) <br> * Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft (CCBE) <br> * Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland (EuRAG) <br></p><br>
                <p>Diese Vorschriften können im Volltext über die Internetseite http://bundesrecht.juris.de/bundesrecht/index.html abgerufen werden. <br></p><br>
                <p>Berufshaftpflichtversicherung nach § 51 BRAO: <br></p><br>
                <p>R+V Versicherung AG, Raiffeisenplatz 1, 65189 Wiesbaden <br></p><br>
                <p>Der Versicherungsschutz bezieht sich nicht auf Haftpflichtansprüche aus Tätigkeiten a) über in anderen Staaten eingerichtete oder unterhaltene Kanzleien oder Büros, b) im Zusammenhang mit der Beratung und Beschäftigung mit außereuropäischem Recht oder c) des Rechtsanwalts vor außereuropäischen Gerichten. Der räumliche Geltungsbereich bezieht sich auf die Mitgliedsländer der EU.<br></p>
                <p>Umsatzsteueridentifikationsnummer RA Biernacki: DE265401917</p>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
export default {
  name: 'Imprint'
};
</script>
